export function areDatesEqualIgnoringTime(date1: Date, date2: Date) {
  const toMidnightUTC = (date: Date) => {
    const midnight = new Date(date)
    midnight.setUTCHours(0, 0, 0, 0)
    return midnight
  }
  return toMidnightUTC(date1).getTime() === toMidnightUTC(date2).getTime()
}

export const getNextDays = (currentDate = new Date(), daysToAdd = 1): Date => {
  const nextDate = new Date(currentDate)
  nextDate.setDate(currentDate.getDate() + daysToAdd)
  return nextDate
}

export const getBeforeDays = (currentDate = new Date(), daysToDec = 1): Date => {
  const nextDate = new Date(currentDate)
  nextDate.setDate(currentDate.getDate() - daysToDec)
  return nextDate
}
