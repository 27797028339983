import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone'
import EditIcon from '@mui/icons-material/Edit'
import { Chip, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import { NumberChip } from '../../../components/NumberChip'
import { TableSkeleton } from '../../../components/TableSkeleton'
import { OrderDto, PickupPointDto, ProductDto } from '../../../generated/api/breadlove'
import { areDatesEqualIgnoringTime } from '../../../utils/date.utils'
import { useStyles } from './styles'

type Props = {
  orders: OrderDto[]
  products: ProductDto[]
  pickupPoints: PickupPointDto[]
  onUpdate: (order: OrderDto) => void
  onDelete: (order: OrderDto) => void
  onPatch: (order: OrderDto) => Promise<void>
  loading: boolean
}

export const OrdersTable: FC<Props> = ({ orders, products, pickupPoints, onUpdate, onDelete, onPatch, loading }) => {
  const { t } = useTranslation()
  const styles = useStyles()
  return !loading ? (
    <TableContainer component={Paper} sx={{ marginBottom: '80px' }}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={styles.header}>{t('orders.table.name')}</TableCell>
            <TableCell sx={styles.header}>{t('orders.table.products')}</TableCell>
            <TableCell sx={styles.header}>{t('orders.table.pickupTime.label')}</TableCell>
            <TableCell sx={styles.header}>{t('orders.table.pickupPoint')}</TableCell>
            <TableCell sx={styles.header}>{t('orders.table.contact')}</TableCell>
            <TableCell sx={styles.header}>{t('orders.table.note')}</TableCell>
            <TableCell sx={styles.header} align="right">
              {t('orders.table.actions')}
            </TableCell>
          </TableRow>
        </TableHead>
        {orders && orders.length > 0 ? (
          <TableBody>
            {orders.map((row) => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" sx={row.open ? styles.cellOpen : styles.cellClose}>
                  {row.type === 'b2b' && (
                    <Chip
                      size="small"
                      label={row.type.toUpperCase()}
                      sx={row.open ? styles.chipOpen : styles.chipClose}
                    />
                  )}
                  {row.name}
                </TableCell>
                <TableCell sx={{ ...(row.open ? styles.cellOpen : styles.cellClose), maxWidth: 500 }}>
                  <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                    {row.products
                      .filter((item) => item.count > 0)
                      .sort((a, b) => a.productId - b.productId)
                      .map((product) => (
                        <NumberChip
                          key={product.productId}
                          label={products.find((value) => value.id === product.productId)!.name}
                          num={product.count}
                        />
                      ))}
                  </Stack>
                </TableCell>
                <TableCell sx={row.open ? styles.cellOpen60 : styles.cellClose60}>
                  {row.pickupTime ? t(`orders.table.pickupTime.${row.pickupTime}`) : ''}
                </TableCell>
                <TableCell sx={row.open ? styles.cellOpen60 : styles.cellClose60}>
                  {pickupPoints.find((value) => value.id === row.pickupPointId)?.name}
                </TableCell>
                <TableCell sx={row.open ? styles.cellOpen60 : styles.cellClose60}>
                  {row.email && (
                    <>
                      {row.email}
                      <br />
                    </>
                  )}
                  {row.phoneNumber}
                </TableCell>
                <TableCell
                  sx={{
                    ...(row.open ? styles.cellOpen60 : styles.cellClose60),
                    maxWidth: 300,
                  }}
                >
                  {row.note}
                </TableCell>
                <TableCell sx={row.open ? styles.cellOpen60 : styles.cellClose60} align="right">
                  <Box whiteSpace="nowrap">
                    {row.open && (
                      <IconButton
                        aria-label="delete"
                        title={t('delete')}
                        onClick={() => onDelete(row)}
                        sx={styles.icon}
                      >
                        <DeleteOutlineTwoToneIcon />
                      </IconButton>
                    )}
                    <IconButton aria-label="update" title={t('update')} onClick={() => onUpdate(row)} sx={styles.icon}>
                      <EditIcon />
                    </IconButton>
                    {areDatesEqualIgnoringTime(new Date(), new Date(row.pickupDate)) && (
                      <IconButton
                        aria-label="finish"
                        title={t('finish')}
                        onClick={() => onPatch(row)}
                        sx={row.open ? styles.openIcon : styles.closeIcon}
                      >
                        {row.open ? <CheckIcon /> : <ClearIcon />}
                      </IconButton>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={6} sx={{ height: 72 }}>
                {t('orders.noData')}
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  ) : (
    <TableSkeleton />
  )
}
